import React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';

// Components
import Description from 'src/components/Shared/Description'

const ToolTypeContent = ({ section }) => {

  if(!section) {
    return null;
  }

  return (
    <div className="ToolTypeContent">
      <h4 className="ToolTypeContent__title small-header">
        { section.title }
      </h4>

      <Description 
        description={ section.description } 
        className="ToolTypeContent__description"
      />

      {
        section.link && 
          <div className="ToolTypeContent__cta-holder">
            <Link 
              className="ToolTypeContent__cta black-button" 
              to={ _.get(section, 'link.page.path', '/') }>
                <span>{ section.link.text }</span>
                <span className="icon">
                  <img src={ require("src/images/icons/arrow-right-white.png").default } />
                </span>
            </Link>
          </div>
      }
    </div>
  )

}

export default ToolTypeContent;