// NPM
import React from 'react'

// Components
import ToolTypeHeader from './ToolTypeHeader'
import ToolTypeContent from './ToolTypeContent'

const ToolType = ({ section }) => {
  if(!section) {
    return null
  }

  return (
    <div className={ `ToolType ${ section.uuid }` }>  
      <ToolTypeHeader section={ section } />
      <ToolTypeContent section={ section } />
    </div>
  )
}

export default ToolType