import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash';

// Components
import ToolType from './ToolType'

const ToolTypes = ({ sections }) => {
  return (
    <div className="ToolTypes">
      <ToolType section={ sections.find(s => s.uuid === "toolType_decision") } />
      <ToolType section={ sections.find(s => s.uuid === "toolType_analytics") } />
    </div>
  )
}

export default ToolTypes;