import React from 'react';
import _ from 'lodash';

const ToolTypeHeader = ({ section }) => {

  if(!section) {
    return null;
  }

  return (
    <div className="ToolTypeHeader">
      <div className="ToolTypeHeader__banner-image"></div>
    </div>
  )

}

export default ToolTypeHeader;